import React from "react";

const NoDataFound = (props) => {
  return (
    <>
      <div className="error-area no-data-found">
        <div className="d-table m-auto">
          <div className="d-table-cell">
            <div className="error-content text-center">
              {/* <div className="">
                <img
                  src={
                    window.location.origin + "/images/no-data-new.png"
                  }
                  alt="img"
                />
              </div> */}
              <h3 className="m-0 mt-5">{props.message ? props.message : "No data found"}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
